import React, { Component } from 'react';

const EMAIL_MESSAGES = {
  success: "Email sent! Thank you!",
  error: "There was an error sending email"
};

const ICON_NAME = {
  cog: "fa-cog",
  spinningCog: "fa-cog fa-spin",
  checkmark: "fa-check",
  errorMark: "fa-times"
};

const MAILGUN_PROXY = 'https://mailgun-email-proxy.herokuapp.com/send-email'

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    textarea: '',
    cogIsSpinning: false,
    iconClassName: ICON_NAME.spinningCog,
    emailMessage: EMAIL_MESSAGES.error,
    showMessage: false,
  }

  formSubmit = (event) => {
    event.preventDefault();
    this.setState({cogIsSpinning: true, iconClassName: ICON_NAME.spinningCog, showMessage: false });
    this.sendEmail()
  }

  handleInput = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  sendEmail() {
    let subjectLine = `E-Mail from ${this.state.name} from IGTP Website`;

    fetch(MAILGUN_PROXY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        from: this.state.email,
        subject: subjectLine,
        text: this.state.textarea
      })
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      this.setState({iconClassName: ICON_NAME.checkmark, showMessage: true, emailMessage: EMAIL_MESSAGES.success});
    })
    .catch(error => {
      this.setState({iconClassName: ICON_NAME.errorMark, emailMessage: EMAIL_MESSAGES.error, showMessage: true});
      console.log("There was an error", error);
    });
  }

  render() {
    return (
      <form onSubmit={this.formSubmit}>
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Name" name="name" onChange={this.handleInput} value={this.state.name} />
        </div>
        <div className="form-group">
          <input type="email" className="form-control" placeholder="E-Mail" name="email" onChange={this.handleInput} value={this.state.email} />
        </div>
        <div className="form-group">
          <textarea className="form-control" placeholder="Ask your question..." rows="4" name="textarea" onChange={this.handleInput} value={this.state.textarea} />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
        <i 
          className={"fas text-primary mb-3 " + this.state.iconClassName} 
          style={{
            padding: "10px", 
            visibility: this.state.cogIsSpinning ? 'visible' : 'hidden'
          }}
        >
        </i>
        <span 
          className="text-primary mb-3" 
          style={{
            visibility: this.state.showMessage ? 'visible':'hidden', 
            fontSize: "0.8em"
          }}
        >
          {this.state.emailMessage}
        </span>
      </form>
    );
  }
}

export default ContactForm;
