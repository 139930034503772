import React, { Component } from 'react';
import ServiceList from './serviceList.js';

class InfoSection extends Component {
  render() {
    return (
      <section className="bg-primary" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h2 className="section-heading text-white">We've got what you need!</h2>
              <hr className="light my-4" />
              <ServiceList />
              <a className="btn btn-light btn-xl js-scroll-trigger" href="#services">Get Started!</a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default InfoSection;