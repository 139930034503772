import React, { Component } from 'react';
import bathroom_tub from '../../images/carouselImages/bathroom_tub.jpg';
import igtp_logo from '../../images/elvis_img_transparent.png';

class Jumbotron extends Component {
  render() {
    return (
      <header className="masthead text-center text-white d-flex" style={{ backgroundImage: `url(${bathroom_tub}` }}>
        <div className="container my-auto">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div style={{ margin: "20px" }}>
                <img src={igtp_logo} alt="" width="50%" />
                <h6>CONTRACTORS</h6>
              </div>
              <h6 className="">Licensed &amp; Insured</h6>
              <h1 className="text-uppercase">
                <strong>The Best Source For All Your Construction Needs</strong>
              </h1>
              <hr />
            </div>
            <div className="col-lg-8 mx-auto">
              <p className="text-faded mb-5">We at I Got The Power Contractors LLC can help you with any of your construction needs and projects. E-Mail us with your specific project to get rates. See our services below.</p>
              <a className="btn btn-primary btn-xl js-scroll-trigger" href="#about">Find Out More</a>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Jumbotron;