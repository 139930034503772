import React, { Component } from 'react';
import ContactForm from './contactForm.js';
import packageJson from '../../../package.json';

class ContactSection extends Component {
  render() {
    return (
      <section id="contact" className="bg-dark text-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <h2 className="section-heading">Let's Get In Touch!</h2>
              <hr className="my-4" />
              <p className="mb-5">Ready to start your next project with us? That's great! Send us an email and we will get back to you as soon as possible!
                Fill out the form below, send us an email at igtpcontractorsllc@gmail.com, or call us (973) 391-5292
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <ContactForm />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <div className="service-box mt-5 mx-auto">
                <a href="https://www.instagram.com/igtp_contractors/" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-4x fa-instagram text-primary mb-3 sr-icon-4" data-toggle="tooltip" data-placement="top" title="Instagram Page" style={{ margin: "5px" }}></i>
                </a>
                <a href="https://www.facebook.com/elvis.fernandez.71868" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-4x fa-facebook-square text-primary mb-3 sr-icon-4" data-toggle="tooltip" data-placement="top" title="Facebook Page" style={{ margin: "5px" }}></i>
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 mx-auto text-center" style={{ fontSize: ".5em" }}>
              {packageJson.version}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactSection;
