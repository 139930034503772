import React, { Component } from 'react';
import bathroom_1 from '../../images/carouselImages/bathroom_1.jpg';
import bathroom_2 from '../../images/carouselImages/bathroom_2.jpg';
import bathroom_tub from '../../images/carouselImages/bathroom_tub.jpg';
import backyard_image from '../../images/carouselImages/backyard_image.jpg';

class GallerySection extends Component {
  render() {
    return (
      <section className="p-0" id="portfolio">
      <div className="container-fluid p-0">
        <div className="row no-gutters popup-gallery">
          <div className="col-lg-4 col-sm-6">
            <a className="portfolio-box" href={bathroom_tub}>
              <img className="img-fluid" src={bathroom_tub} alt="" />
              <div className="portfolio-box-caption">
                <div className="portfolio-box-caption-content">
                  <div className="project-category text-faded">
                    Category
                  </div>
                  <div className="project-name">
                    Project Name
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-sm-6">
            <a className="portfolio-box" href={bathroom_1}>
              <img className="img-fluid" src={bathroom_1} alt="" />
              <div className="portfolio-box-caption">
                <div className="portfolio-box-caption-content">
                  <div className="project-category text-faded">
                    Category
                  </div>
                  <div className="project-name">
                    Project Name
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-sm-6">
            <a className="portfolio-box" href={bathroom_2}>
              <img className="img-fluid" src={bathroom_2} alt="" />
              <div className="portfolio-box-caption">
                <div className="portfolio-box-caption-content">
                  <div className="project-category text-faded">
                    Category
                  </div>
                  <div className="project-name">
                    Project Name
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4 col-sm-6">
            <a className="portfolio-box" href={backyard_image}>
              <img className="img-fluid" src={backyard_image} alt="" />
              <div className="portfolio-box-caption">
                <div className="portfolio-box-caption-content">
                  <div className="project-category text-faded">
                    Category
                  </div>
                  <div className="project-name">
                    Project Name
                  </div>
                </div>
              </div>
            </a>
          </div>

        </div>
      </div>
    </section>
    );
  }
}

export default GallerySection;
