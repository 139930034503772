import React, { Component } from 'react';
import NavBar from './components/NavBar/navBar.js';
import Jumbotron from './components/JumboTron/jumbotron.js';
import InfoSection from './components/InfoSection/infoSection.js';
import ServicesSection from './components/ServicesSection/servicesSection.js';
import ContactSection from './components/ContactSection/contactSection.js';
import GallerySection from './components/GallerySection/gallerySection.js';
import './App.css';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <Jumbotron />
        <InfoSection />
        <ServicesSection />
        <GallerySection />
        <ContactSection />
      </React.Fragment>
    );
  }
}

export default App;
