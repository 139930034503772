import React, { Component } from 'react';

class ServiceList extends Component {
  render() {
    return (
      <ul className="list-group" style={{margin: "10px"}}>
        <li className="list-group-item">Kitchen Remodel</li>
        <li className="list-group-item">Bath</li>
        <li className="list-group-item">Framing</li>
        <li className="list-group-item">Tiles</li>
        <li className="list-group-item">Hard Wood</li>
        <li className="list-group-item">Electrical</li>
        <li className="list-group-item">Plumbing</li>
      </ul>
    );
  }
}

export default ServiceList;
