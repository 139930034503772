import React, { Component } from 'react';

class ServicesSection extends Component {
  render() {
    return (
      <section id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">At Your Service</h2>
              <hr className="my-4" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 text-center">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-4x fa-gem text-primary mb-3 sr-icon-1"></i>
                <h3 className="mb-3">Quality Service</h3>
                <p className="text-muted mb-0">Our work is done with utmost care.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-4x fa-dollar-sign text-primary mb-3 sr-icon-2"></i>
                <h3 className="mb-3">Affordable</h3>
                <p className="text-muted mb-0">We offer competitive pricing for everything we do.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-4x fa-trophy text-primary mb-3 sr-icon-3"></i>
                <h3 className="mb-3">Best In New Jersey</h3>
                <p className="text-muted mb-0">We've been doing this for so long, there is no one better.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 text-center">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-4x fa-wrench text-primary mb-3 sr-icon-4"></i>
                <h3 className="mb-3">All The Tools</h3>
                <p className="text-muted mb-0">We have all the best tools at our disposal to do all sorts of jobs!</p>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    );
  }
}

export default ServicesSection;
